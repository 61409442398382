.App {
  margin: auto;
  max-width: 900px;
}

.tile {
  border: 1px solid rgb(196,196,196);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.95);
}

.flex-container {
  margin-top: 10px;
  display: flex;
}

.Chart {
  height: 300px;
  padding: 5px 10px 10px 5px;
  max-height: 300px;
  width: 80%;
  position: relative;
}

.ChartTitle {
  margin-top: 100px;
}

.Options{
  margin-left: 10px;
  padding: 0px 20px 20px 20px;
  height: auto;
  width: 20%;
  text-align: left;
  position: relative;
}

.top5-container {
  margin-top: 10px;
  display: flex;
}

.top5-child{
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  font-size: 12pt;
  width: 50%;
  padding-bottom: 60px;
  position: relative;
}

.topButton {
  border: 0px solid rgb(196,196,196);
  padding: 10px 20px;
  margin-top: 10px;
  font-size: 12pt;
  border-radius: 2px;
  background-color: rgb(230,230,230);
  position: absolute;
  bottom: 10px;
  width: 120px;
  left: calc(50% - 60px);
}

.top5PerCapita{
  margin-left: 10px;
}

table {
  margin: auto;
  text-align: left;
}

.Dropdown {
  text-align: left;
  position: relative;
  z-index: 3;
}

.Footer {
  font-size: 8pt;
  color: #666666;
  padding-bottom: 5px;
  height: auto;
}


a {
  color:  #424242
}

.Header {
  position: relative;
  padding-bottom: 5px;
}

.Header a{
  position: absolute;
  right: 0;
  bottom: 10px;
  margin-right: 0px;
  width: 100px;
}

.overlay {
  position: absolute;
  width: auto; 
  height: auto; 
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.05); 
  z-index: 2; 
}

@media screen and (max-width: 930px) {
  .flex-container {
    flex-direction: column;
    align-content: left;
  }

  .Options{
    margin-top: 10px;
    margin-left: 0px; 
    width: auto;
    font-size: 12pt;
  }

  .Chart{
    margin: 0px;
    width: auto;
  }
}

@media screen and (max-width: 700px) {
  .top5-child{
    width: auto;
  }

  .top5PerCapita{
    margin-left: 0;
    margin-top: 10px;
  }

  .top5-container {
    flex-direction: column;
  }
}
