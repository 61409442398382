body {
  margin: 10px;
  text-align: center;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}

html {
  height: 100vh;
  width: 100vw;
  margin: auto;
  padding: 0;
  background: url('../images/co2.jpg') no-repeat center center fixed;
  background-color: hsla(0,0%,80%,1);
  background-size: cover;
  background-blend-mode: overlay;
}

h3 {
  font-size: 16pt;
}